<template>
  <div>

    <div class="cover cover--medium" :style="'background-image: url(' + require('@/assets/cover-join.jpg') + ')'"></div>
    
    <template v-if="hasJoined">
        <div class="wrapper wrapper--spaced wrapper--centered white-box">
            <h1 class="title title--spaced">Thank You For Adding Your Voice</h1>       
            <p class="text">We believe British Columbians deserve a true, common sense alternative the next time they head to the polls – and with supporters like you, we believe we can make that vision a reality.</p>
            <p class="text">Thank you, sincerely, for your interest in, and support of, our campaign – we’ll be in touch!</p>
            <p class="text">The Common Sense BC Team</p>
        </div>
    </template>

    <template v-else>
        <div class="blue-box blue-box--light jumbotron">
            <div class="wrapper wrapper--spaced wrapper--centered">
                <h1 class="title title--white title--spaced">Help Start a New Political Alternative in British Columbia</h1>
                <p class="text">We’re looking for British Columbians who believe its time for a new political alternative in B.C. and who are ready to step up to make it happen.</p>
                <p class="text">Register below and Aaron, Angelo, Connor or someone else from our team will be in touch in the days ahead with additional details on how we’re planning on moving forward.</p>
                <form  class="form form--boxless form--column" style="display: inline-flex; margin:auto;" method="post" action="https://commonsensebc.com/webservices/forms.php">
                    <div style="max-width: 600px; display: flex; flex-wrap: wrap;">
                        <div class="form__item form__item--duo form__item--large"><input name="firstname" type="text" placeholder="First Name*" class="cover__input" autocomplete="given-name" required/></div>
                        <div class="form__item form__item--duo form__item--large"><input name="lastname" type="text" placeholder="Last Name*" class="cover__input" autocomplete="family-name" required/></div>
                        <div class="form__item form__item--duo form__item--large"><input name="email" type="email" placeholder="Email Address*" class="cover__input" autocomplete="email" required/></div>
                        <div class="form__item form__item--duo form__item--large"><input name="phone" type="tel" placeholder="Phone Number*" class="cover__input cover__input--small" autocomplete="tel" required/></div>
                        <div class="form__item form__item--duo form__item--large"><input name="zip" type="text" placeholder="Postal Code*" class="cover__input cover__input--small" autocomplete="postal-code" pattern="[A-Za-z][0-9][A-Za-z][\s]?[0-9][A-Za-z][0-9]" required/></div>
                        <div class="form__item"><label class="option"><input type="checkbox" name="volunteer" value="yes"/> I would like to learn more about becoming a volunteer</label></div>
                    </div>
                    <input type="hidden" name="source" value="commonsensebc_newpoliticalalternative"/>
                    <input style="max-width: 600px; margin-top: 20px;" type="submit" class="button button--yellow button--large" value="Register" />
                </form>
            </div>
        </div>
    </template>
  </div>
</template>

<script>
export default {
    mounted() {

    },
    components: {
        
    },
    data() {
        return {
            selectedTier: null
        }
    },
    computed: {
        hasJoined () {
            return this.$router.currentRoute.query.hasOwnProperty('sent');
        }
    },
    methods: {

    }

}
</script>

<style scoped>
.tier {  }
.tier--background { background: rgba(195, 213, 255, 0.5); }
.tier__item { min-height: 600px; display: flex; flex-direction: column; border: 1px solid #EBECED; margin-bottom: 60px; padding: 0 40px; padding-bottom: 40px; border-radius: 20px; overflow: hidden; }
.tier--supporter, .tier--insider, .tier--producer { margin-left: 30px; } 
.tier__title { width: 80%; max-width: 300px; height: 120px; align-self: center; display: flex; align-items: center; justify-content: center; padding: 0 20px; box-sizing: border-box; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; font-size: 3rem; text-transform: uppercase; text-align: center; }
.tier--follower .tier__title { background: #C3D5FF; color: #062573; }
.tier--supporter .tier__title { background: #1F4FC5; color: white; }
.tier--insider .tier__title { background: #071F5A; color: white; }
.tier--club .tier__title { background: #062573; color: white; }
.tier--producer .tier__title { background: #E0231C; color: white; }
.tier__pricing { padding: 20px 0; margin-bottom: 20px; border-bottom: 1px solid rgba(195, 213, 255, 0.5); text-transform: uppercase; font-size: 2rem; font-weight: 600; text-align: center; } 
.tier__inclusions { padding: 20px 0; text-align: left; flex:1; }
.tier__inclusion { padding: 5px 0; }
@media (max-width: 1100px) {
   .tier__item { min-height: 0; margin-bottom: 20px; }
   .tier__item--politics-explained { margin-right: 0; }
   .tier--supporter, .tier--insider, .tier--producer { margin-left: 0; } 
}

@media (max-width: 768px) {

}

@media (max-width: 526px) {

}
</style>
